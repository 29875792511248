import { createSelector } from 'reselect';
import { isPassed, isInProgress } from 'utils/comparison';
import * as questionSelectors from 'store/questions/selectors';
import {
  getMasteryScoreValue,
  isOverallMasteryScore,
  isQuestionPoolEnabled
} from 'store/settings/selectors';
import { objectToArray } from 'utils/object';
import { RootAppState } from 'store/types';
import { INFORMATION_CONTENT } from 'constants/questionTypes';
import { hasGuidedReattempt } from '../common/selectors';

export const getSectionQuestions = (state: RootAppState, sectionId: string) =>
  objectToArray(state.questions).filter(question => question.sectionId === sectionId);

export const getSection = (state: RootAppState, sectionId: string) => state.sections[sectionId];

export const getSections = createSelector(
  (state: RootAppState) => state.sections,
  sections => objectToArray(sections)
);

export const getSectionsCount = (state: RootAppState) => getSections(state).length;

const getQuestionIds = createSelector(getSection, section => (section && section.questions) || []);

export const getQuestionCount = createSelector(
  getSection,
  section => section && section.questions.length
);

export const getCourseQuestionCount = (state: RootAppState) => {
  if (isQuestionPoolEnabled(state)) {
    return (
      state &&
      Object.values(state.questions).filter((question: any) => question.isPoolQuestion).length
    );
  }
  return state && state.questions && Object.keys(state.questions).length;
};

export const getQuestionIndex = createSelector(
  getQuestionIds,
  (state, sectionId, questionId) => questionId,
  (questionIds, questionId) => questionIds.indexOf(questionId)
);

export const affectsProgress = createSelector(
  getQuestionIds,
  state => state,
  (questionIds, state) =>
    questionIds.find(
      (questionId: string) => questionSelectors.getQuestion(state, questionId).affectsProgress
    ) !== undefined
);

export const getAffectProgressSections = createSelector(
  getSections,
  state => state,
  (sections, state) => sections.filter(section => affectsProgress(state, section.id))
);

export const getAffectProgressSectionsCount = (state: RootAppState) =>
  getAffectProgressSections(state).length;

export const getSectionsPassedCount = createSelector(
  getSections,
  sections => sections.filter(section => isPassed(section.status)).length
);

export const isSectionsPassed = createSelector(getAffectProgressSections, sections =>
  sections.every(section => isPassed(section.status))
);

export const isSectionsFailed = createSelector(
  getAffectProgressSections,
  state => state,
  (sections, state) =>
    !isSectionsPassed(state) && sections.every(section => !isInProgress(section.status))
);

export const getAnsweredSectionsCount = createSelector(
  getAffectProgressSections,
  sections => sections.filter(section => section.isSectionAnswered).length
);

export const getQuestions = createSelector(
  getQuestionIds,
  state => state,
  (questionIds, state) =>
    questionIds.reduce((result: any, questionId: string) => {
      result.push(questionSelectors.getQuestion(state, questionId));
      return result;
    }, [])
);

export const getAffectProgressQuestions = createSelector(getQuestions, questions =>
  questions.filter((question: any) => question.affectsProgress)
);

export const getAnsweredOnceQuestionsInSection = createSelector(
  getAffectProgressQuestions,
  questions => questions.filter((question: any) => question.isAnswered)
);

export const getPreviouslyCorrectQuestionsCountInSection = createSelector(
  getAffectProgressQuestions,
  questions =>
    questions.filter(
      (question: any) =>
        question.isAnswered && question.isPreviousAnswerCorrect && !question.isAnswerChanged
    ).length
);

export const getChangedAnswersQuestionCountInSection = createSelector(
  getAffectProgressQuestions,
  questions =>
    questions.filter((question: any) => question.isAnswered && question.isAnswerChanged).length
);

export const isSectionFailed = createSelector(
  getAffectProgressQuestions,
  state => state,
  (questions, state) =>
    questions.every((question: any) => !questionSelectors.hasAvailableAttempt(state, question))
);

export const getSectionScore = (state: RootAppState) =>
  isOverallMasteryScore(state) ? 100 : getMasteryScoreValue(state);

export const getAffectProgressQuestionsCountInSection = (state: RootAppState, sectionId: string) =>
  getAffectProgressQuestions(state, sectionId).length;

export const getAnsweredSectionQuestionsCount = (state: RootAppState, sectionId: string) => {
  if (hasGuidedReattempt(state)) {
    return (
      getPreviouslyCorrectQuestionsCountInSection(state, sectionId) +
      getChangedAnswersQuestionCountInSection(state, sectionId)
    );
  }
  return getAnsweredOnceQuestionsInSection(state, sectionId).length;
};

export const getSectionProgress = (state: RootAppState, sectionId: string) => {
  let answeredQuestionsCountInSection;
  const affectProgressQuestionsCountInSection = getAffectProgressQuestionsCountInSection(
    state,
    sectionId
  );
  if (hasGuidedReattempt(state)) {
    answeredQuestionsCountInSection =
      getPreviouslyCorrectQuestionsCountInSection(state, sectionId) +
      getChangedAnswersQuestionCountInSection(state, sectionId);
  } else {
    answeredQuestionsCountInSection = getAnsweredSectionQuestionsCount(state, sectionId);
  }
  return affectProgressQuestionsCountInSection > 0
    ? Math.floor((answeredQuestionsCountInSection * 100) / affectProgressQuestionsCountInSection)
    : 0;
};

export const getSectionProgressWithPreviousAttempt = (state: RootAppState, sectionId: string) => {
  const affectProgressQuestionsCountInSection = getAffectProgressQuestionsCountInSection(
    state,
    sectionId
  );
  const answeredQuestionsCountInSection =
    getPreviouslyCorrectQuestionsCountInSection(state, sectionId) +
    getChangedAnswersQuestionCountInSection(state, sectionId);
  return affectProgressQuestionsCountInSection > 0
    ? Math.floor((answeredQuestionsCountInSection * 100) / affectProgressQuestionsCountInSection)
    : 0;
};

export const getCorrectlyAffectProgressQuestionsCountInSection = createSelector(
  getQuestions,
  questions =>
    questions.filter((question: any) => question.isAnsweredCorrectly && question.affectsProgress)
      .length
);

export const getAffectCorrectlyAnsweredQuestionsCount = (state: RootAppState, sectionId: string) =>
  Math.ceil(
    getSectionScore(state) / (100 / getAffectProgressQuestionsCountInSection(state, sectionId))
  );

export const sectionExists = (state: RootAppState, sectionId: string) =>
  sectionId && Object.keys(state.sections).includes(sectionId);

export const getCorrectlyAnsweredQuestionsCount = (state: RootAppState) =>
  objectToArray(state.questions).filter((question: any) => question.isAnsweredCorrectly).length;

export const getNonInfoContentQuestions = (state: RootAppState, sectionId: string) => {
  const questions = getSectionQuestions(state, sectionId) || [];
  return questions
    .filter((question: any) => question.type !== INFORMATION_CONTENT)
    .map((question: any) => question.id);
};

export const getAnsweredAffectProgressQuestionsCountInSection = createSelector(
  getQuestions,
  questions =>
    questions.filter((question: any) => question.isAnswered && question.affectsProgress).length
);

export const getSectionIndex = createSelector(
  getSections,
  (state: RootAppState, sectionId: string) => sectionId,
  (sections, sectionId) => sections.map(section => section.id).indexOf(sectionId)
);

export const isSectionAnswered = (state: RootAppState, sectionId: string) => {
  const affectProgressQuestionsCountInSection = getAffectProgressQuestionsCountInSection(
    state,
    sectionId
  );
  const answeredQuestionsCountInSection = getAnsweredSectionQuestionsCount(state, sectionId);
  return affectProgressQuestionsCountInSection === answeredQuestionsCountInSection;
};

export const isLearningObjective = createSelector(
  getSections,
  (state: RootAppState, sectionId: string) => sectionId,
  (sections, sectionId) => sections.map(section => section.id).indexOf(sectionId) >= 0
);

export const isNotAffectProgressSection = (state: RootAppState, sectionId: string) => {
  const sectionQuestions = getSectionQuestions(state, sectionId);
  return sectionQuestions.every((question: any) => !question.affectsProgress);
};

export const getNotAffectProgressSections = createSelector(
  getSections,
  state => state,
  (sections, state) => sections.filter(section => isNotAffectProgressSection(state, section.id))
);
