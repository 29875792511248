export type CourseState = {
  started: boolean;
  title: string;
  id: string;
  templateId: string;
  createdBy: string;
  createdOn: Date;
  hasIntroductionContent: boolean;
  isLoadingFailed: boolean;
  attemptId: string | null;
  hasBeenContinued: boolean;
  result: number;
  isCompleted: boolean;
  isCertificateDownloaded: boolean;
  score: number;
  status: number;
  sections: string[];
  introductions?: string[];
  passedAfterwords: string[];
  failedAfterwords: string[];
  previewQuestionId?: string;
  isAttemptSubmitted?: boolean;
  courseAttempt: number;
  isLaunched: boolean;
  isProgressRestoreFailed?: boolean;
  isProgressRestoreFetched?: boolean;
  timeSpent: TimeSpentState;
  isRandomizedOptionsLoaded?: boolean;
  isCourseFinished?: boolean;
  poolIds?: string[];
};

export type TimeSpentState = {
  accumulatedValueInMilliseconds: number;
  incrementStartedAt: Date;
};

export enum ActionTypes {
  COURSE_DATA_LOADING_STARTED = 'COURSE_DATA_LOADING_STARTED',
  COURSE_DATA_LOADED = 'COURSE_DATA_LOADED',
  COURSE_DATA_LOADING_FAILED = 'COURSE_DATA_LOADING_FAILED',
  COURSE_SCORE_UPDATED = 'COURSE_SCORE_UPDATED',
  COURSE_LAUNCHED = 'COURSE_LAUNCHED',
  COURSE_STARTED = 'COURSE_STARTED',
  COURSE_SUBMIT_AT_ONCE = 'COURSE_SUBMIT_AT_ONCE',
  COURSE_UPDATE_ATTEMPTS = 'COURSE_UPDATE_ATTEMPTS',
  COURSE_REATTEMPTED_SUBMIT_AT_ONCE = 'COURSE_REATTEMPTED_SUBMIT_AT_ONCE',
  COURSE_PROGRESS_RESTORED = 'COURSE_PROGRESS_RESTORED',
  COURSE_PROGRESS_RESTORE_FAILED = 'COURSE_PROGRESS_RESTORE_FAILED',
  COURSE_PROGRESS_RESTORE_FETCHED = 'COURSE_PROGRESS_RESTORE_FETCHED',
  COURSE_CLEANUP = 'COURSE_CLEANUP',
  COURSE_FINISHED = 'COURSE_FINISHED',
  COURSE_FINALIZED = 'COURSE_FINALIZED',
  CLEAR_PREVIEW_QUESTION_ID = 'CLEAR_PREVIEW_QUESTION_ID',
  CERTIFICATE_DOWNLOADED = 'CERTIFICATE_DOWNLOADED',
  COURSE_IS_PASSED = 'COURSE_PASSED',
  TIME_SPENT_UPDATED = 'TIME_SPENT_UPDATED',
  COURSE_IS_FAILED = 'COURSE_FAILED',
  COURSE_RANDOMIZED_OPTIONS_LOADED = 'COURSE_RANDOMIZED_OPTIONS_LOADED',
  POOL_IDS_LOADED = 'POOL_IDS_LOADED'
}

interface CourseIsPassed {
  type: ActionTypes.COURSE_IS_PASSED;
  payload: {
    status: number;
  };
}

interface CourseIsFailed {
  type: ActionTypes.COURSE_IS_FAILED;
  payload: {
    status: number;
  };
}

interface CourseSubmitAtOnce {
  type: ActionTypes.COURSE_SUBMIT_AT_ONCE;
}

interface CourseSubmitAtOnceLimited {
  type: ActionTypes.COURSE_UPDATE_ATTEMPTS;
  payload: {
    courseAttempt: number;
  };
}

interface CourseReAttemptedSubmitAtOnce {
  type: ActionTypes.COURSE_REATTEMPTED_SUBMIT_AT_ONCE;
}
interface CourseScoreUpdate {
  type: ActionTypes.COURSE_SCORE_UPDATED;
  payload: {
    score: number;
    status: number;
  };
}

interface CourseDataLoadingStarted {
  type: ActionTypes.COURSE_DATA_LOADING_STARTED;
}

interface CourseDataLoaded {
  type: ActionTypes.COURSE_DATA_LOADED;
  payload: CourseState;
}

interface CourseDataLoadedFailed {
  type: ActionTypes.COURSE_DATA_LOADING_FAILED;
  reason: any;
}

interface CourseProgressRestored {
  type: ActionTypes.COURSE_PROGRESS_RESTORED;
  payload: {
    attemptId: string;
  };
}

interface CourseProgressRestoreFailed {
  type: ActionTypes.COURSE_PROGRESS_RESTORE_FAILED;
  reason: any;
}

interface CourseProgressRestoreFetched {
  type: ActionTypes.COURSE_PROGRESS_RESTORE_FETCHED;
}

interface CourseLaunched {
  type: ActionTypes.COURSE_LAUNCHED;
  payload: {
    attemptId?: string;
  };
}

interface CourseStarted {
  type: ActionTypes.COURSE_STARTED;
}

interface CourseCleanUp {
  type: ActionTypes.COURSE_CLEANUP;
}

interface CourseFinished {
  type: ActionTypes.COURSE_FINISHED;
}

interface CourseFinalized {
  type: ActionTypes.COURSE_FINALIZED;
}

interface ClearPreviewQuestionId {
  type: ActionTypes.CLEAR_PREVIEW_QUESTION_ID;
}

interface CertificateDownloaded {
  type: ActionTypes.CERTIFICATE_DOWNLOADED;
  payload: any;
}

interface TimeSpentUpdated {
  type: ActionTypes.TIME_SPENT_UPDATED;
  payload: {
    timeSpent: {
      accumulatedValueInMilliseconds: number;
      incrementStartedAt: Date;
    };
  };
}

interface CourseRandomizedOptionsLoaded {
  type: ActionTypes.COURSE_RANDOMIZED_OPTIONS_LOADED;
}

interface PoolIdsLoaded {
  type: ActionTypes.POOL_IDS_LOADED;
  payload: {
    poolIds: string[];
  }
}

export type CourseActionTypes =
  | CourseIsPassed
  | CourseIsFailed
  | CourseScoreUpdate
  | CourseDataLoadingStarted
  | CourseDataLoadedFailed
  | CourseProgressRestored
  | CourseLaunched
  | CourseStarted
  | CourseCleanUp
  | CourseFinished
  | CourseFinalized
  | ClearPreviewQuestionId
  | CertificateDownloaded
  | CourseSubmitAtOnce
  | CourseSubmitAtOnceLimited
  | CourseReAttemptedSubmitAtOnce
  | CourseDataLoaded
  | CourseProgressRestoreFailed
  | CourseProgressRestoreFetched
  | TimeSpentUpdated
  | CourseRandomizedOptionsLoaded
  | PoolIdsLoaded;
