import * as settingsActions from 'store/settings/actions';
import * as modulesActions from 'store/modules/actions';
import * as tocActions from 'store/treeOfContent/actions';
import * as userActions from 'store/user/actions';
import { isReviewMode, isResource, isCourse } from 'store/settings/selectors';
import { isLowerResolution, clearQueryString, getValueFromUrl } from 'utils/window';
import logger from 'utils/logger';
import { MEDIA_TABLET } from 'constants/screenResolutions';
import { ThunkResult } from 'store/types';
import connectionAlert from 'core/connectionAlert';
import configuration from 'core/configuration';
import resourceLoader from 'core/http/resourceLoader';
import { isAppLoaded } from './selectors';
import { ActionTypes } from './types';
import * as resourcesActions from '../resource/actions';
import * as courseActions from '../course/actions';
import contentLoader from '../../core/http/contentLoader';
import { LTI_CALLBACK_URL_PARAMETER_NAME } from '../../constants/common';

const loadMaterial = (data: any): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (isResource(getState())) {
    await dispatch(resourcesActions.load(data));
  } else {
    connectionAlert.subscribe();
    configuration.subscribe();

    await dispatch(courseActions.load(data));
    await dispatch(userActions.load());

    if (isReviewMode(getState())) {
      await dispatch(modulesActions.enableReview());
    }
    const ltiCallbackUrl = getValueFromUrl(LTI_CALLBACK_URL_PARAMETER_NAME);
    if (ltiCallbackUrl) {
      dispatch(modulesActions.subscribeLti(ltiCallbackUrl));
    }
  }
};

export const load = (): ThunkResult => async (dispatch, getState) => {
  if (isAppLoaded(getState())) return;

  try {
    const materialData = await contentLoader.loadMaterialData();
    resourceLoader.cacheBuster = materialData.createdOn;

    await dispatch(settingsActions.load());
    await dispatch(modulesActions.subscribeProgressStorage());
    await dispatch(loadMaterial(materialData));
    clearQueryString();
    dispatch({ type: ActionTypes.APP_LOADED });
  } catch (e) {
    logger.error(e);
    dispatch({ type: ActionTypes.APP_LOADING_FAILED, reason: e });
  }
};

export const resolutionChanged = (): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.APP_RESOLUTION_CHANGED,
    payload: {
      isLowResolution: isLowerResolution(MEDIA_TABLET)
    }
  });
  dispatch(tocActions.adaptToResolution());
};

export const launchMaterial = (): ThunkResult => (dispatch, getState) => {
  if (isResource(getState())) {
    return dispatch(resourcesActions.launch());
  }
  if (isCourse(getState())) {
    return dispatch(courseActions.launch());
  }

  throw new Error('The resource type is undefined');
};
