import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'components/common/Popup';
import { getPopupVisibility } from 'store/popup/selectors';
import {
  getBackground,
  isTrackingEnabled,
  isScormMode,
  isResource,
  getSelectedLanguage
} from 'store/settings/selectors';
import { isAuthenticated } from 'store/user/selectors';
import { isReviewEnabled } from 'store/modules/selectors';
import { isCourseAccessLimited } from 'store/course/selectors';
import { isLoaderVisibility } from 'store/loader/selectors';
import Reviewable from 'components/review/Reviewable';
import { HINT_FOR_GENERAL_COMMENT } from 'constants/review';
import { HYPHENATED_LANGUAGES } from 'constants/language';
import { RootAppState } from 'store/types';
import UserMenu from '../../userMenu';
import {
  LayoutBaseStyles,
  LayoutContainer,
  BackgroundContainer,
  PageContainer,
  PageContentContainer,
  PageContentChild,
  PageSidebarContainer,
  PageSidebarChild,
  BasicStylesSidebar
} from './Layout.styled';

type PageSidebarProps = {
  children: any;
  className?: string;
  props?: any;
};
export const PageSidebar = ({ children, className, ...props }: PageSidebarProps) => (
  <PageSidebarContainer className={className} {...props}>
    <PageSidebarChild>{children}</PageSidebarChild>
  </PageSidebarContainer>
);

type BasicStyledSidebarProps = {
  children?: any;
  className?: string;
  background?: any;
};
export const BasicStyledSidebar = ({
  children,
  className = '',
  ...props
}: BasicStyledSidebarProps) => (
  <BasicStylesSidebar className={className} {...props}>
    <PageSidebarChild>{children}</PageSidebarChild>
  </BasicStylesSidebar>
);

type PageContentProps = {
  children: any;
  className?: string;
  props?: any;
};
export const PageContent = ({ children, ...props }: PageContentProps) => (
  <PageContentContainer {...props}>
    <PageContentChild>{children}</PageContentChild>
  </PageContentContainer>
);

type LayoutProps = {
  children: any;
  background: { [key: string]: any };
  isUserAuthenticated: boolean;
  popupExpanded: boolean;
  trackingEnabled: boolean;
  isAccessLimited: boolean;
  isLoaderVisible?: boolean;
  isScorm: boolean;
  reviewMode: boolean;
  isResourceType: boolean;
  className?: string;
  hasUnhandledError?: boolean;
  selectedLanguage?: string;
};

export class Layout extends Component<LayoutProps, {}> {
  render() {
    const {
      background,
      children,
      popupExpanded,
      className,
      hasUnhandledError,
      isLoaderVisible,
      isResourceType
    } = this.props;
    const bgHeader = background.header;
    return (
      <>
        <LayoutBaseStyles shouldEnableHyphenation={this.shouldEnableHyphenation()} />
        <LayoutContainer className={className}>
          <Reviewable isGeneral={true} hintName={HINT_FOR_GENERAL_COMMENT}>
            {!isLoaderVisible && !isResourceType && (
              <BackgroundContainer background={bgHeader} aria-hidden={popupExpanded} />
            )}
            <PageContainer aria-hidden={popupExpanded}>{children}</PageContainer>
            {this.shouldRenderUserMenu() && !isLoaderVisible && (
              <UserMenu ariaHidden={popupExpanded} hasUnhandledError={hasUnhandledError || false} />
            )}
            {popupExpanded && <Popup />}
          </Reviewable>
        </LayoutContainer>
      </>
    );
  }

  shouldEnableHyphenation = () => {
    const { selectedLanguage = 'en' } = this.props;
    return HYPHENATED_LANGUAGES.includes(selectedLanguage);
  };

  shouldRenderUserMenu() {
    const {
      isUserAuthenticated,
      trackingEnabled,
      isAccessLimited,
      isScorm,
      reviewMode,
      isResourceType
    } = this.props;

    return (
      (isUserAuthenticated &&
        trackingEnabled &&
        !isAccessLimited &&
        !isResourceType &&
        !reviewMode) ||
      isScorm
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    background: getBackground(state),
    popupExpanded: getPopupVisibility(state),
    isUserAuthenticated: isAuthenticated(state),
    trackingEnabled: isTrackingEnabled(state),
    isAccessLimited: isCourseAccessLimited(state),
    isLoaderVisible: isLoaderVisibility(state),
    isScorm: isScormMode(state),
    reviewMode: isReviewEnabled(state),
    isResourceType: isResource(state),
    selectedLanguage: getSelectedLanguage(state)
  };
}

export default connect(mapStateToProps)(Layout);
