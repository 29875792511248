import * as courseActions from 'store/course/actions';
import * as sectionActions from 'store/sections/actions';
import * as contentActions from 'store/contents/actions';
import { getContents } from 'store/contents/selectors';
import eventEmitter, { events } from 'core/events/eventEmitter';
import { getSection } from 'store/sections/selectors';
import { INFORMATION_CONTENT } from 'constants/questionTypes';
import progressStorage from 'core/progressStorage';
import uniqBy from 'lodash.uniqby';
import {
  isQuestionPoolEnabled,
  isScoringOfContentPagesAllowed,
  isAnswersFromPreviousAttemptEnabled,
  shouldSubmitAllQuestions
} from '../settings/selectors';
import {
  getQuestion,
  getScore,
  hasBeenOpened,
  getQuestionAttempt,
  getQuestions,
  getQuestionRetries,
  getPoolQuestions,
  getAnswerData,
  getStatementsData
} from './selectors';
import { getCourseAttempt, getPoolIds, getProgressedCourseData } from '../course/selectors';
import * as CourseTypes from '../course/types';
import { ActionTypes } from './types';
import { ThunkResult } from '../types';

export const questionsLoaded = (questions: any): ThunkResult => (dispatch, getState) => {
  const scoreContentPages = isScoringOfContentPagesAllowed(getState());
  if (scoreContentPages && questions) {
    Object.keys(questions).forEach(key => {
      const question = questions[key];
      if (question.type === INFORMATION_CONTENT) {
        question.affectsProgress = scoreContentPages;
      }
      question.attemptNumber = question.attemptNumber ? question.attemptNumber : 0;
    });
  }
  dispatch({
    type: ActionTypes.QUESTIONS_LOADED,
    payload: questions
  });
};

export const pureAnswer = (questionId: string): ThunkResult => async (dispatch, getState) => {
  const { question, score, attempt } = getAnswerData(getState(), questionId);

  dispatch({
    type: ActionTypes.QUESTION_ANSWERED,
    payload: { id: question.id, score, attempt }
  });

  dispatch(sectionActions.updateProgress(question.sectionId));
  await dispatch(courseActions.updateProgress());
};

export const answer = (questionId: string): ThunkResult => async (dispatch, getState) => {
  await dispatch(pureAnswer(questionId));

  eventEmitter.emit(events.QUESTION_ANSWERED, {
    statementsData: [getStatementsData(getState(), questionId)],
    state: getState()
  });
};

export const answerBeforeSubmit = (questionId: string): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const question = getQuestion(getState(), questionId);
  const attempt = getCourseAttempt(getState());
  dispatch({
    type: ActionTypes.QUESTION_ANSWERED_BEFORE_SUBMIT,
    payload: { id: question.id, attempt, isAnswerChanged: true }
  });

  const questionContent = getContents(getState(), question.questionContent);
  eventEmitter.emit(events.SUBMIT_ONCE_QUESTION_ANSWERED, [
    getQuestion(getState(), questionId),
    getSection(getState(), question.sectionId),
    questionContent,
    attempt
  ]);

  dispatch(sectionActions.updateSection(question.sectionId));
  await dispatch(courseActions.updateProgress());
};

export const updateScore = (questionId: string): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const question = getQuestion(getState(), questionId);
  const score = getScore(getState(), question);
  const attempt = getCourseAttempt(getState());
  dispatch({
    type: ActionTypes.QUESTION_ANSWERED,
    payload: { id: question.id, score, attempt }
  });

  dispatch(sectionActions.updateProgress(question.sectionId));
};

const getSubmitAllAtOnceStatementsData = (state: any, questions: any) => {
  let { questionsStatementsData, sectionsStatementsData } = questions.reduce(
    (data: any, { id: questionId }: { id: string }) => {
      const { questionData, section } = getStatementsData(state, questionId);

      return {
        questionsStatementsData: [...data.questionsStatementsData, questionData],
        sectionsStatementsData: [...data.sectionsStatementsData, section]
      };
    },
    { questionsStatementsData: [], sectionsStatementsData: [] }
  );

  if (!isScoringOfContentPagesAllowed(state)) {
    questionsStatementsData = questionsStatementsData.filter((questionsStatementData: any) => {
      const [question] = questionsStatementData;
      return question.type !== INFORMATION_CONTENT;
    });
  }

  sectionsStatementsData = uniqBy(sectionsStatementsData, 'section.id');
  const courseStatementData = getProgressedCourseData(state);

  return [questionsStatementsData, sectionsStatementsData, courseStatementData];
};

export const submitAllAtOnce = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch(courseActions.makeSubmitAtOnce());
  dispatch(courseActions.updateSubmitAtOnceAttemptNumber());
  const questions = isQuestionPoolEnabled(getState())
    ? getPoolQuestions(getState(), getPoolIds(getState()))
    : getQuestions(getState());

  await Promise.all(
    questions.map(async ({ id: questionId }) => {
      await dispatch(updateScore(questionId));
      await dispatch(courseActions.updateProgress());
    })
  );

  const statementsData = getSubmitAllAtOnceStatementsData(getState(), questions);

  eventEmitter.emit(events.SUBMIT_ONCE_QUESTION_SUBMITTED, [true]);
  eventEmitter.emit(events.COURSE_SUBMIT, {
    statementsData,
    state: getState(),
    isSubmitOnce: true
  });
};

export const informationContentExperienced = (
  questionId: string
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (!isScoringOfContentPagesAllowed(getState())) {
    return;
  }
  const question = getQuestion(getState(), questionId);

  if (shouldSubmitAllQuestions(getState())) {
    const courseAttempt = getCourseAttempt(getState());
    dispatch({
      type: ActionTypes.QUESTION_ANSWERED_BEFORE_SUBMIT,
      payload: { id: question.id, attempt: courseAttempt, isAnswerChanged: true }
    });

    dispatch(sectionActions.updateSection(question.sectionId));
    await dispatch(courseActions.updateProgress());
  } else {
    const score = getScore(getState(), question);
    const questionAttempt = getQuestionAttempt(getState(), questionId) + 1;
    dispatch({
      type: ActionTypes.QUESTION_ANSWERED,
      payload: { id: question.id, score, attempt: questionAttempt }
    });
    await dispatch(sectionActions.updateProgress(question.sectionId));
    await dispatch(courseActions.updateProgress());
    eventEmitter.emit(events.INFORMATION_CONTENT_EXPERIENCED, {
      statementsData: [getStatementsData(getState(), questionId)],
      state: getState()
    });
  }
};

export const markAsOpened = (questionId: string): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  if (hasBeenOpened(getState(), questionId)) {
    return;
  }

  dispatch({
    type: ActionTypes.QUESTION_OPENED,
    payload: { questionId, hasBeenOpened: true }
  });

  const questionType = getQuestion(getState(), questionId).type;
  if (questionType === INFORMATION_CONTENT) {
    dispatch(informationContentExperienced(questionId));
  }
};

export const resetProgress = (question: any): ThunkResult => (dispatch, getState) => {
  const initialScore = question.score;
  if (!isAnswersFromPreviousAttemptEnabled(getState())) {
    const isPoolQuestion = () => !!question.isPoolQuestion;
    dispatch({
      type: ActionTypes.QUESTION_PROGRESS_RESET,
      payload: { id: question.id, isPoolQuestion: isPoolQuestion(), type: question.type }
    });
    eventEmitter.emit(events.RESET_QUESTION_PROGRESS, question.id);
  } else {
    dispatch({
      type: ActionTypes.QUESTION_PROGRESS_RESET_KEEP_ANSWER,
      payload: { id: question.id }
    });
  }

  if (initialScore === 0) {
    return;
  }

  dispatch(sectionActions.updateProgress(question.sectionId));
  dispatch(courseActions.updateProgress());
};

export const cleanup = (questionId: string): ThunkResult => (dispatch, getState) => {
  const question = getQuestion(getState(), questionId);
  const isPoolQuestion = () => !!question.isPoolQuestion;
  dispatch({
    type: ActionTypes.QUESTION_PROGRESS_RESET,
    payload: { id: question.id, isPoolQuestion: isPoolQuestion(), type: question.type }
  });

  dispatch({
    type: ActionTypes.QUESTION_PROGRESS_RESET_ATTEMPT,
    payload: { id: question.id }
  });

  dispatch({
    type: ActionTypes.QUESTION_OPENED,
    payload: { questionId, hasBeenOpened: false }
  });
};

export const cleanupKeepAnswers = (questionId: string): ThunkResult => (dispatch, getState) => {
  const question = getQuestion(getState(), questionId);
  const score = getScore(getState(), question);

  dispatch({
    type: ActionTypes.QUESTION_PROGRESS_RESET_KEEP_ANSWER,
    payload: { id: question.id, isPreviousAnswerCorrect: score === 100 }
  });

  dispatch({
    type: ActionTypes.QUESTION_OPENED,
    payload: { questionId, hasBeenOpened: false }
  });
};

export const loadFeedback = (questionId: string): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const question = getQuestion(state, questionId);
  if (question.correctFeedback.length) {
    dispatch(contentActions.loadContents(getContents(state, question.correctFeedback)));
  }
  if (question.incorrectFeedback.length) {
    dispatch(contentActions.loadContents(getContents(state, question.incorrectFeedback)));
  }
  if (question.explanationBlocks.length) {
    dispatch(contentActions.loadContents(getContents(state, question.explanationBlocks)));
  }
};

export const restoreProgress = (
  questionId: string,
  questionAnswers: any,
  attempt: number,
  showPrevAnswer: boolean
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  try {
    if (!showPrevAnswer) {
      dispatch({
        type: ActionTypes.QUESTION_RESTORE_RESPONSE,
        payload: { id: questionId, response: questionAnswers, attempt }
      });
    } else {
      dispatch({
        type: ActionTypes.QUESTION_RESTORE_SHOW_PREV_ANSWER,
        payload: { id: questionId, response: questionAnswers }
      });
    }

    const question = getQuestion(getState(), questionId);
    const score = getScore(getState(), question);
    if (!showPrevAnswer) {
      dispatch({
        type: ActionTypes.QUESTION_RESTORE_PREVIOUSLY_CORRECT_FLAG,
        payload: { id: questionId, isPreviousAnswerCorrect: score === 100 }
      });
    }
    const { allQuestionsSubmitted } = progressStorage;
    const isSubmitAllAtOnce = shouldSubmitAllQuestions(getState());
    if (isSubmitAllAtOnce && !allQuestionsSubmitted) {
      dispatch({
        type: ActionTypes.QUESTION_PROGRESS_RESTORE_BEFORE_SUBMIT,
        payload: { id: questionId, attempt, isAnswerChanged: false }
      });
    } else {
      dispatch({
        type: ActionTypes.QUESTION_PROGRESS_RESTORE,
        payload: { id: questionId, score, attempt }
      });
    }
  } catch (e) {
    console.error(e);
    dispatch({ type: CourseTypes.ActionTypes.COURSE_PROGRESS_RESTORE_FAILED, reason: e });
  }
};

export const animateFeedback = (data: {
  questionId: string;
  isAnimating: boolean;
}): ThunkResult => dispatch => {
  const { questionId, isAnimating } = data;
  dispatch({
    type: ActionTypes.FEEDBACK_ANIMATING,
    payload: { id: questionId, isFeedbackAnimating: isAnimating }
  });
};

export const updateRetries = (questionId: string): ThunkResult => (dispatch, getState) => {
  const questionRetries = getQuestionRetries(getState(), questionId);
  dispatch({
    type: ActionTypes.QUESTION_UPDATE_RETRY,
    payload: { questionId, retries: questionRetries + 1 }
  });
};
