import { ProgressStatus } from 'constants/progressStatus';
import { getTimerStatus } from 'store/timer/selectors';
import { TimerStatus } from 'constants/timer';
import {
  getAffectProgressQuestions,
  getSectionProgress,
  isSectionAnswered,
  isSectionFailed,
  getNotAffectProgressSections,
  getSectionProgressWithPreviousAttempt
} from './selectors';
import * as settingsSelector from '../settings/selectors';
import { isAttemptSubmitted, getCourseAttempt } from '../course/selectors';

import { ActionTypes } from './types';
import { RootAppState, ThunkResult } from '../types';
import { loadUrls } from '../navigation/actions';
import { hasGuidedReattempt } from '../common/selectors';

const checkSectionFailed = (state: RootAppState, sectionId: string) => {
  if (settingsSelector.shouldSubmitAllQuestions(state)) {
    return (
      settingsSelector.isAttemptsLimited(state) &&
      isAttemptSubmitted(state) &&
      getCourseAttempt(state) >= settingsSelector.getLimitedAttemptsNumber(state)
    );
  }
  return isSectionFailed(state, sectionId);
};

const checkSectionFailedTimedOut = (state: RootAppState, score: number) => {
  const isOverallMasteryScore = settingsSelector.isOverallMasteryScore(state);
  const masteryScore = settingsSelector.getMasteryScoreValue(state);
  if (settingsSelector.getTimerEnabled(state)) {
    return (
      ((isOverallMasteryScore && score < 100) ||
        (!isOverallMasteryScore && score < masteryScore)) &&
      getTimerStatus(state) === TimerStatus.STOPPED
    );
  }
  return false;
};

export const updateScore = (sectionId: string): ThunkResult => (dispatch, getState) => {
  const isOverallMasteryScore = settingsSelector.isOverallMasteryScore(getState());
  const masteryScore = settingsSelector.getMasteryScoreValue(getState());
  const questions = getAffectProgressQuestions(getState(), sectionId);

  const correctQuestions = questions.filter((question: any) => question.isAnsweredCorrectly);

  const score = questions.length
    ? Math.floor((correctQuestions.length * 100) / questions.length)
    : 100;

  let status = ProgressStatus.IN_PROGRESS;

  if (
    (isOverallMasteryScore && score === 100) ||
    (!isOverallMasteryScore && score >= masteryScore)
  ) {
    status = ProgressStatus.PASSED;
  } else if (
    checkSectionFailed(getState(), sectionId) ||
    checkSectionFailedTimedOut(getState(), score)
  ) {
    status = ProgressStatus.FAILED;
  }
  dispatch({
    type: ActionTypes.SECTION_SCORE_UPDATED,
    payload: {
      id: sectionId,
      score,
      status
    }
  });
};

export const updateSectionAnsweredProgress = (sectionId: string): ThunkResult => (
  dispatch,
  getState
) => {
  const sectionProgress = hasGuidedReattempt(getState())
    ? getSectionProgressWithPreviousAttempt(getState(), sectionId)
    : getSectionProgress(getState(), sectionId);

  dispatch({
    type: ActionTypes.SECTION_ANSWERED_SCORE,
    payload: {
      id: sectionId,
      sectionProgress
    }
  });
};

export const sectionAnswered = (sectionId: string): ThunkResult => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SECTION_ANSWERED,
    payload: {
      id: sectionId,
      isSectionAnswered: isSectionAnswered(getState(), sectionId)
    }
  });
};

export const updateSection = (sectionId: string): ThunkResult => dispatch => {
  dispatch(updateScore(sectionId));
  dispatch(sectionAnswered(sectionId));
  dispatch(updateSectionAnsweredProgress(sectionId));
};

export const updateProgress = (sectionId: string): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch(updateSection(sectionId));
};

export const onSectionIsPassed = (sectionId: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.SECTION_IS_PASSED,
    payload: {
      id: sectionId,
      status: ProgressStatus.PASSED
    }
  });
};

export const sectionsLoaded = (sections: any): ThunkResult => (dispatch, getState) => {
  const isShowContentPagesEnabled = settingsSelector.isShowContentPagesEnabled(getState());
  dispatch({ type: ActionTypes.SECTIONS_LOADED, payload: sections });
  dispatch(loadUrls(sections, isShowContentPagesEnabled));
};

export const cleanup = (sectionId: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.SECTION_SCORE_UPDATED,
    payload: {
      id: sectionId,
      score: 0,
      status: ProgressStatus.IN_PROGRESS
    }
  });

  dispatch({
    type: ActionTypes.SECTION_ANSWERED,
    payload: {
      id: sectionId,
      isSectionAnswered: false
    }
  });

  dispatch({
    type: ActionTypes.SECTION_ANSWERED_SCORE,
    payload: {
      id: sectionId,
      sectionProgress: 0
    }
  });
};

export const updateProgressUnaffectedSections = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  if (!settingsSelector.shouldSubmitAllQuestions(getState())) {
    const notAffectProgressSections = getNotAffectProgressSections(getState());
    if (notAffectProgressSections.length > 0) {
      notAffectProgressSections.forEach((section: any) => {
        if (section.status !== ProgressStatus.PASSED) {
          dispatch(updateProgress(section.id));
        }
      });
    }
  }
};
