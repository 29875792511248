import { createSelector } from 'reselect';
import { objectToArray } from 'utils/object';
import {
  MULTIPLE_CHOICE,
  SINGLE_CHOICE,
  SINGLE_CHOICE_IMAGE,
  STATEMENT_CHOICE,
  OPEN_QUESTION,
  RANKING_TEXT,
  SCENARIO,
  FILL_IN_THE_BLANK,
  DRAG_AND_DROP_TEXT,
  TEXT_MATCHING,
  HOTSPOT,
  INFORMATION_CONTENT
} from 'constants/questionTypes';
import { TimerStatus } from 'constants/timer';
import { getContents } from 'store/contents/selectors';
import { getTimerStatus } from 'store/timer/selectors';
import * as multipleChoiceSelectors from './multipleChoice/selectors';
import * as statementChoiceSelectors from './statementChoice/selectors';
import * as openQuestionSelector from './openQuestion/selectors';
import * as rankingTextSelector from './rankingText/selectors';
import * as scenarioSelector from './scenario/selectors';
import * as fillInTheBlankSelector from './fillInTheBlank/selectors';
import * as dragAndDropTextSelector from './dragAndDropText/selectors';
import * as textMatching from './textMatching/selectors';
import * as hotspot from './hotspot/selectors';
import * as informationContent from './informationContent/selectors';
import { getXapiStatus, getProgressedCourseData } from '../course/selectors';
import { getSection } from '../sections/selectors';
import { RootAppState } from '../types';
import {
  isAttemptsLimited,
  getLimitedAttemptsNumber,
  isQuestionPoolEnabled,
  getTimerEnabled,
  isScoringOfContentPagesAllowed,
  getMasteryScoreValue
} from '../settings/selectors';
import { hasGuidedReattempt } from '../common/selectors';

export const hasAvailableAttempt = (state: RootAppState, question: any) =>
  isAttemptsLimited(state)
    ? question.type !== INFORMATION_CONTENT &&
      question.score < 100 &&
      question.attemptNumber < getLimitedAttemptsNumber(state)
    : true;

export const isAttemptTimedOut = (state: RootAppState, question: any) =>
  getTimerEnabled(state)
    ? question.score < 100 && getTimerStatus(state) !== TimerStatus.STOPPED
    : true;

export const getQuestion = (state: RootAppState, questionId: string) => state.questions[questionId];

export const getQuestionSection = (state: RootAppState, questionId: string) =>
  state.questions[questionId].sectionId;

export const getQuestions = createSelector(
  (state: RootAppState) => state.questions,
  questions => objectToArray(questions)
);

export const getAffectProgressQuestions = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.filter(
        (question: any) => question.affectsProgress && question.isPoolQuestion
      );
    }
    return questions.filter((question: any) => question.affectsProgress);
  }
);

export const isAllQuestionsFailed = createSelector(
  getAffectProgressQuestions,
  state => state,
  (questions, state) => questions.every((question: any) => !hasAvailableAttempt(state, question))
);

export const isAllQuestionsTimedOut = createSelector(
  getAffectProgressQuestions,
  state => state,
  (questions, state) => questions.every((question: any) => !isAttemptTimedOut(state, question))
);

export const isAllQuestionsAnswered = createSelector(
  getQuestions,
  questions =>
    !questions.filter((question: any) => !question.isAnswered && question.affectsProgress).length
);

export const isAllSurveyQuestionsAnswered = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return true; // When questionPool is enabled there are no survey questions
    }
    return !questions.filter((question: any) => question.isSurvey && !question.isAnswered).length;
  }
);

export const getQuestionsCount = createSelector(getQuestions, questions => questions.length);

export const getQuestionAnswers = (state: RootAppState, questionId: string) =>
  state.questions[questionId].answers;

export const getQuestionResponse = (state: RootAppState, questionId: string) =>
  state.questions && state.questions[questionId] && state.questions[questionId].response;

export const getAffectProgressQuestionsCount = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.filter(
        (question: any) => question.affectsProgress && question.isPoolQuestion
      ).length;
    }
    return questions.filter((question: any) => question.affectsProgress).length;
  }
);

export const hasReAnswerableQuestions = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (!isAttemptsLimited(state)) {
      return true;
    }
    return questions.some(
      (question: any) =>
        !question.isAnsweredCorrectly &&
        question.type !== INFORMATION_CONTENT &&
        question.attemptNumber < getLimitedAttemptsNumber(state)
    );
  }
);
export const hasScoreContentPages = createSelector(
  getQuestions,
  state => state,
  (questions, state) =>
    isScoringOfContentPagesAllowed(state) &&
    questions.some((question: any) => question.type === INFORMATION_CONTENT)
);

export const getCorrectlyAnsweredQuestionsCountOfPreviousAttempt = createSelector(
  getAffectProgressQuestions,
  questions =>
    questions.filter(
      (question: any) =>
        question.isAnswered && question.isPreviousAnswerCorrect && !question.isAnswerChanged
    ).length
);

export const getChangedAnswersQuestionCount = createSelector(
  getAffectProgressQuestions,
  questions =>
    questions.filter((question: any) => question.isAnswered && question.isAnswerChanged).length
);

export const getAnsweredAffectProgressQuestionsCount = createSelector(
  getAffectProgressQuestions,
  state => state,
  (questions, state) => {
    if (hasGuidedReattempt(state)) {
      return (
        getCorrectlyAnsweredQuestionsCountOfPreviousAttempt(state) +
        getChangedAnswersQuestionCount(state)
      );
    }
    return questions.filter((question: any) => question.isAnswered && question.affectsProgress)
      .length;
  }
);

export const getAnsweredQuestionsCount = createSelector(
  getQuestions,
  questions => questions.filter((question: any) => question.isAnswered).length
);

export const getAnsweredQuestionsProgress = (state: RootAppState) => {
  let answeredAffectProgressQuestionCount;
  const affectProgressQuestionsCount = getAffectProgressQuestionsCount(state);
  if (hasGuidedReattempt(state)) {
    answeredAffectProgressQuestionCount =
      getCorrectlyAnsweredQuestionsCountOfPreviousAttempt(state) +
      getChangedAnswersQuestionCount(state);
  } else {
    answeredAffectProgressQuestionCount = getAnsweredAffectProgressQuestionsCount(state);
  }
  return affectProgressQuestionsCount > 0
    ? Math.floor((answeredAffectProgressQuestionCount * 100) / affectProgressQuestionsCount)
    : 0;
};

export const getCorrectlyAffectProgressAnsweredQuestionsCount = createSelector(
  getQuestions,
  questions =>
    questions.filter((question: any) => question.affectsProgress && question.isAnsweredCorrectly)
      .length
);

export const getQuestionCountToReachMasteryScore = (state: RootAppState) =>
  Math.ceil((getAffectProgressQuestionsCount(state) / 100) * getMasteryScoreValue(state));

export const getQuestionPercentageToReachMasteryScore = (state: RootAppState) =>
  Math.ceil(
    (getCorrectlyAffectProgressAnsweredQuestionsCount(state) /
      getAffectProgressQuestionsCount(state)) *
      100
  );

export const isQuestionAnswered = createSelector(
  getQuestion,
  question => question && question.isAnswered
);

export const isQuestionAnsweredCorrectly = createSelector(
  getQuestion,
  question => question && question.isAnsweredCorrectly
);

export const getQuestionAttempt = createSelector(
  getQuestion,
  question => question && question.attemptNumber
);

export const hasBeenOpened = createSelector(
  getQuestion,
  question => question && question.hasBeenOpened
);

export const isPreviousAnswerCorrect = createSelector(
  getQuestion,
  question => question && question.isPreviousAnswerCorrect
);

export const hasCourseBeenStarted = createSelector(
  getQuestions,
  questions => questions.filter((question: any) => question.hasBeenOpened).length > 0
);

export const getLastOpenedQuestion = (questions: any[]) =>
  questions.reduce((acc, question) => (question.hasBeenOpened ? question : acc));

// TODO: should be Question type
export const getScore = (state: RootAppState, question: any) => {
  switch (question.type) {
    case SINGLE_CHOICE_IMAGE:
    case SINGLE_CHOICE:
    case MULTIPLE_CHOICE:
      return multipleChoiceSelectors.getScore(state, question);
    case STATEMENT_CHOICE:
      return statementChoiceSelectors.getScore(state, question);
    case OPEN_QUESTION:
      return openQuestionSelector.getScore(state, question);
    case RANKING_TEXT:
      return rankingTextSelector.getScore(state, question);
    case SCENARIO:
      return scenarioSelector.getScore(state, question);
    case FILL_IN_THE_BLANK:
      return fillInTheBlankSelector.getScore(state, question);
    case DRAG_AND_DROP_TEXT:
      return dragAndDropTextSelector.getScore(state, question);
    case TEXT_MATCHING:
      return textMatching.getScore(state, question);
    case HOTSPOT:
      return hotspot.getScore(state, question);
    case INFORMATION_CONTENT:
      return informationContent.getScore(state, question);
    default:
      throw new Error(`Unable to calculate score for ${question.type} question`);
  }
};

export const questionExists = (state: RootAppState, questionId: string) =>
  questionId && Object.keys(state.questions).includes(questionId);

export const isFeedbackAnimating = createSelector(
  getQuestion,
  question => question.isFeedbackAnimating
);

export const getPoolQuestions = (state: RootAppState, poolIds: string[]) =>
  poolIds.map(poolId => state.questions[poolId]);

export const getAllQuestionsAnswers = createSelector(
  getQuestions,
  state => state,
  (questions, state) =>
    questions.map((question: any) => {
      const answers = getQuestionAnswers(state, question.id);
      return [question.id, answers ? answers.map((answer: any) => answer.id) : ''];
    })
);

export const getSectionId = (state: RootAppState, questionId: string) =>
  state.questions[questionId].sectionId;

export const isAllAffectedQuestionsAnswered = createSelector(
  getAffectProgressQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.every(({ isAnswered, isPoolQuestion }: any) => isAnswered && isPoolQuestion);
    }
    return questions.every((question: any) => question.isAnswered);
  }
);

export const getUnansweredQuestions = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.filter(
        ({ isAnswered, isPoolQuestion }: any) => !isAnswered && isPoolQuestion
      );
    }
    return questions.filter(({ isAnswered }: any) => !isAnswered);
  }
);

export const hasAnyQuestionOpened = createSelector(getQuestions, questions =>
  questions.some(question => question.hasBeenOpened)
);

export const getQuestionRetries = createSelector(getQuestion, question => question?.retries || 0);

export const getAllSurveyQuestions = createSelector(getQuestions, questions =>
  questions.filter(
    (question: any) => question.hasOwnProperty('isSurvey') && question.isSurvey === true
  )
);

export const getCourseNonInformationQuestionCount = createSelector(
  getQuestions,
  questions =>
    questions.filter(
      (question: any) => question.affectsProgress && question.type !== INFORMATION_CONTENT
    ).length
);

export const getCourseInformationQuestionCount = createSelector(
  getQuestions,
  questions =>
    questions.filter(
      (question: any) => question.affectsProgress && question.type === INFORMATION_CONTENT
    ).length
);

export const filterStatementsInformationContent = (statementsData: any[]) =>
  statementsData.filter(({ questionData }) => {
    const [question] = questionData;
    return question.type !== INFORMATION_CONTENT;
  });

export const getAnswerData = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  const score = getScore(state, question);
  const attempt = getQuestionAttempt(state, questionId) + 1;

  return { question, score, attempt };
};

export const getStatementsData = (state: RootAppState, questionId: string) => {
  const { question, attempt } = getAnswerData(state, questionId);
  const questionContent = getContents(state, question.questionContent);

  const section = getSection(state, question.sectionId);
  const xapiStatus = getXapiStatus(state, section.status);

  return {
    questionData: [
      getQuestion(state, questionId),
      getSection(state, question.sectionId),
      questionContent,
      attempt
    ],
    course: getProgressedCourseData(state),
    section: { section, xapiStatus }
  };
};

export const getFirstIncorrectOrPendingQuestion = createSelector(
  getAffectProgressQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.find(
        (question: any) =>
          question.type !== INFORMATION_CONTENT &&
          question.isPoolQuestion &&
          ((question.isAnswered && !question.isAnsweredCorrectly) || !question.isAnswered)
      );
    }
    if (isScoringOfContentPagesAllowed(state)) {
      return questions.find(
        (question: any) =>
          (question.isAnswered && !question.isAnsweredCorrectly) || !question.isAnswered
      );
    }
    return questions.find(
      (question: any) =>
        question.type !== INFORMATION_CONTENT &&
        ((question.isAnswered && !question.isAnsweredCorrectly) || !question.isAnswered)
    );
  }
);

export const getFirstIncorrectQuestionPreviousAttemptFromPool = createSelector(
  getAffectProgressQuestions,
  state => state,
  questions =>
    questions.find(
      (question: any) =>
        question.type !== INFORMATION_CONTENT &&
        question.isPoolQuestion &&
        !question.isPreviousAnswerCorrect
    )
);

export const getAnsweredQuestionsProgressWithPreviousAttempt = (state: RootAppState) => {
  const affectProgressQuestionsCount = getAffectProgressQuestionsCount(state);
  const answeredAffectProgressQuestionCount =
    getCorrectlyAnsweredQuestionsCountOfPreviousAttempt(state) +
    getChangedAnswersQuestionCount(state);
  return affectProgressQuestionsCount > 0
    ? Math.floor((answeredAffectProgressQuestionCount * 100) / affectProgressQuestionsCount)
    : 0;
};
