import initialState, { getInitialQuestionResponse } from 'store/initialState';

import responseReducers from './responseReducers';
import { QuestionActionTypes, ActionTypes, QuestionsState } from './types';

export default function questionReducer(
  state: QuestionsState = initialState.questions,
  action: QuestionActionTypes
) {
  switch (action.type) {
    case ActionTypes.QUESTIONS_LOADED:
      return Object.assign({}, action.payload);
    case ActionTypes.QUESTION_PROGRESS_RESTORE:
    case ActionTypes.QUESTION_ANSWERED:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isAnswered: true,
          score: action.payload.score,
          isAnsweredCorrectly: action.payload.score === 100,
          attemptNumber: action.payload.attempt
        }
      };
    case ActionTypes.QUESTION_PROGRESS_RESTORE_BEFORE_SUBMIT:
    case ActionTypes.QUESTION_ANSWERED_BEFORE_SUBMIT:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isAnswered: true,
          score: 0,
          isAnsweredCorrectly: false,
          attemptNumber: action.payload.attempt,
          isAnswerChanged: action.payload.isAnswerChanged
        }
      };
    case ActionTypes.QUESTION_UPDATE_SCORE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isAnswered: true,
          score: action.payload.score,
          isAnsweredCorrectly: action.payload.score === 100
        }
      };
    case ActionTypes.QUESTION_OPENED:
      return {
        ...state,
        [action.payload.questionId]: {
          ...state[action.payload.questionId],
          hasBeenOpened: action.payload.hasBeenOpened
        }
      };
    case ActionTypes.QUESTION_PROGRESS_RESET:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isAnswered: false,
          isAnsweredCorrectly: false,
          isPreviousAnswerCorrect: false,
          score: 0,
          isPoolQuestion: action.payload.isPoolQuestion,
          response: getInitialQuestionResponse(action.payload.type)
        }
      };
    case ActionTypes.QUESTION_PROGRESS_RESET_ATTEMPT:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          attemptNumber: 0
        }
      };
    case ActionTypes.QUESTION_PROGRESS_RESET_KEEP_ANSWER:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          hasBeenOpened: false,
          isAnswered: false,
          isAnsweredCorrectly: false,
          score: 0,
          isPreviousAnswerCorrect: action.payload.isPreviousAnswerCorrect,
          isAnswerChanged: false
        }
      };
    case ActionTypes.QUESTION_RESTORE_RESPONSE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          response: action.payload.response,
          hasBeenOpened: true,
          attemptNumber: action.payload.attempt
        }
      };
    case ActionTypes.QUESTION_RESTORE_SHOW_PREV_ANSWER:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          response: action.payload.response
        }
      };
    case ActionTypes.FEEDBACK_ANIMATING:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFeedbackAnimating: action.payload.isFeedbackAnimating
        }
      };
    case ActionTypes.QUESTION_UPDATE_RETRY:
      return {
        ...state,
        [action.payload.questionId]: {
          ...state[action.payload.questionId],
          retries: action.payload.retries
        }
      };
    case ActionTypes.QUESTION_RESTORE_PREVIOUSLY_CORRECT_FLAG:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isPreviousAnswerCorrect: action.payload.isPreviousAnswerCorrect
        }
      };
    default:
      return responseReducers(state, action);
  }
}
