export const ICON = {
  SHAPES: {
    ROUND_FULL_COLOR: 'round-fullColor',
    ROUND_OUTLINED: 'round-outlined',
    ROUND_SHADED: 'round-shaded'
  }
} as const;

export const OPACITY = {
  PERCENT_30: '0.3',
  PERCENT_100: '1.0'
} as const;

export const BUTTON = {
  LAYOUT: {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SIMPLE: 'simple'
  },
  TYPE: {
    SUBMIT: 'submit',
    BUTTON: 'button',
    RESET: 'reset'
  }
} as const;

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

export const TOC = { EXPANDED_WIDTH: 380, COLLAPSED_WIDTH: 56, TAIL_WIDTH: 30 };

export const Z_INDEX = {
  SIDEBAR: 10,
  TOC: 200,
  COLLAPSED_TOC: 210,
  TOC_TAIL: 220,
  BLOCKOUT: 100
};

export const LINK = {
  LAYOUT: {
    DEFAULT: 'default',
    FORM: 'form'
  }
};

export const INPUT_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  EMAIL: 'email'
} as const;

export const TOOLTIP = {
  CORRECT: 'correct',
  INCORRECT: 'incorrect'
} as const;

export const SWITCH_BUTTON_LAYOUT = {
  QUESTION: 'question',
  CHECKLIST: 'checklist'
};
