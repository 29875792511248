import styled, { createGlobalStyle, css } from 'styled-components';
import media from 'components/mixins/media';
import { headerBackground } from 'components/mixins/backgrounds';
import { transparentize } from 'utils/color';

type LayoutBaseStylesProps = {
  shouldEnableHyphenation: boolean;
};

export const LayoutBaseStyles = createGlobalStyle<LayoutBaseStylesProps>`
  * {
    hyphens: ${props => (props.shouldEnableHyphenation ? 'auto' : 'none')};
  }
`;

export const fullSizeAbsoluteBlock = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const LayoutContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  ${media.small`
    position: fixed;
    width: 100vw;
    height: 100%;
  `};

  & > .reviewable-container {
    display: flex;
  }
`;

type BackgroundContainerProps = {
  background: any;
};
export const BackgroundContainer = styled.div<BackgroundContainerProps>`
  position: relative;
  flex: 0 0 50%;
  height: 100vh;
  ${props => headerBackground(props.background)};
  ${media.tablet`
    flex: 1 1 100%;
  `};
`;

export const PageSidebarChild = styled.div.attrs({
  'data-sidebar-child': true
})`
  ${fullSizeAbsoluteBlock};
  ${media.tablet`
    position: relative;
    width: 100%;
    height: auto;
  `};
`;

export const PageContentChild = styled.div.attrs({
  'data-content-child': true
})`
  ${fullSizeAbsoluteBlock};
  ${media.tablet`
    position: relative;
    width: 100%;
    height: auto;
  `};
`;

export const PageContainer = styled.div`
  ${fullSizeAbsoluteBlock};
  overflow: hidden;
`;

export const PageSidebarContainer = styled.aside.attrs({ 'data-sidebar': true })``;

export const PageContentContainer = styled.div.attrs({
  'data-content': true
})``;

type BasicStylesSidebarProps = {
  background?: any;
};
export const BasicStylesSidebar = styled.aside.attrs({ 'data-sidebar': true })<
  BasicStylesSidebarProps
>`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow-y: auto;
  background-color: ${props =>
    props.background.header.coverOverlay.enabled
      ? transparentize(
          props.background.header.coverOverlay.color,
          props.background.header.coverOverlay.opacity
        )
      : null};
`;
