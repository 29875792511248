import { isScoringOfContentPagesAllowed } from 'store/settings/selectors';
import { hasBeenOpened, isPreviousAnswerCorrect } from 'store/questions/selectors';
import { RootAppState } from '../../types';

// eslint-disable-next-line import/prefer-default-export
export const getScore = (state: RootAppState, question: any) => {
  if (isScoringOfContentPagesAllowed(state)) {
    return hasBeenOpened(state, question.id) || isPreviousAnswerCorrect(state, question.id)
      ? 100
      : 0;
  }

  return 0;
};
